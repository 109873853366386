import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import generalAPI from '../../api/generalAPI'

const initialState = {
    fullLogo: "",
    logo: "",
    status: null,
    message: ""
}

export const getGeneral = createAsyncThunk('general', async (_, thunkAPI) => {
    try {
        const res = await generalAPI.getGeneral()
        return {
            fullLogo: res.data.data[0].fullLogo,
            logo: res.data.data[0].logo,
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(error)
    }
})


const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {},
    extraReducers: {
        [getGeneral.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getGeneral.fulfilled]: (state, { payload }) => {
            state.fullLogo = payload.fullLogo
            state.logo = payload.logo
            state.status = 'success'
        },
        [getGeneral.rejected]: (state, { payload }) => {
            state.status = 'failed'
            state.message = payload.message
        },
    }
})

export default generalSlice.reducer
import { combineReducers } from '@reduxjs/toolkit'
import { withReduxStateSync } from 'redux-state-sync'

import authReducer from './features/authSlice'
import localeReducer from './features/localeSlice'
import generalReducer from './features/generalSlice'
import storeLocationReducer from './features/storeLocationSlice'
import configReducer from './features/configSlice'
import isCollapseReducer from './features/isCollapseSlice'
import orderNumReducer from './features/orderNumSlice'
import orderListReducer from './features/orderListSlice'

const rootReducer = combineReducers({
    user: authReducer,
    locale: localeReducer,
    general: generalReducer,
    storeLocation: storeLocationReducer,
    config: configReducer,
    isCollapse: isCollapseReducer,
    orderNum: orderNumReducer,
    orderList: orderListReducer
})

export default withReduxStateSync(rootReducer)

import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

const ProtectedRoute = ({redirectPath = '/login', children
}) => {

    const user = useSelector(x => x.user.isLoggedIn)


    if (!user) {
        return <Navigate to={redirectPath} replace />
    }
    
    return children ? children : <Outlet />

}

export default ProtectedRoute

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import orderAPI from '../../api/orderAPI'

const initialState = {
    totalDineIn: 0,
    totalTakeaway: 0,
    totalKitchen: 0,
}

export const getTotalDineIn = createAsyncThunk('total-dinein', async (storeLocation, thunkAPI) => {
    try {
        const res = await orderAPI.getTotalDineIn(storeLocation)
        return {
            totalDineIn: res.data.meta.pagination.total
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const getTotalTakeaway = createAsyncThunk('total-takeaway', async (storeLocation, thunkAPI) => {
    try {
        const res = await orderAPI.getTotalTakeaway(storeLocation)
        return {
            totalTakeaway: res.data.meta.pagination.total
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const getTotalKitchen = createAsyncThunk('total-kitchen', async (storeLocation, thunkAPI) => {
    try {
        const res = await orderAPI.getTotalKitchen(storeLocation)
        return {
            totalKitchen: res.data.meta.pagination.total
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(error)
    }
})


const orderNumSlice = createSlice({
    name: 'orderNum',
    // initialState: {
    //     general: initState,
    //     status: null,
    //     message: ''
    // },
    initialState,
    reducers: {},
    extraReducers: {
        [getTotalDineIn.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getTotalDineIn.fulfilled]: (state, { payload }) => {
            state.totalDineIn = payload.totalDineIn
            state.status = 'success'
        },
        [getTotalDineIn.rejected]: (state, { payload }) => {
            state.status = 'failed'
            state.message = payload.message
        },
        [getTotalTakeaway.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getTotalTakeaway.fulfilled]: (state, { payload }) => {
            state.totalTakeaway = payload.totalTakeaway
            state.status = 'success'
        },
        [getTotalTakeaway.rejected]: (state, { payload }) => {
            state.status = 'failed'
            state.message = payload.message
        },
        [getTotalKitchen.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getTotalKitchen.fulfilled]: (state, { payload }) => {
            state.totalKitchen = payload.totalKitchen
            state.status = 'success'
        },
        [getTotalKitchen.rejected]: (state, { payload }) => {
            state.status = 'failed'
            state.message = payload.message
        },
    }
})

// export const { changeLocale } = orderNumSlice.actions

export default orderNumSlice.reducer
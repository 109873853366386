import baseAPI from './baseAPI'

const getCurrency = async () => {
    return await baseAPI.get('currency')
}

const getConfig = async () => {
    return await baseAPI.get('currency/combine')
}

export default { getCurrency, getConfig }
import { LOCALES } from '../locales'

export default {
    [LOCALES.KHMER]: {
        "login": "ចូលប្រើប្រាស",
        // Menu
        "dashboard": "ដាស់ប៊ត",
        "product": "ផលិតផល",
        "cate": "ក្រុម",
        "brand": "ម៉ាក",
        "type": "ប្រភេទ",
        "inventory": "សារពើភ័ណ្ឌ",
        "store": "ហាង",
        "warehouse": "ឃ្លាំង",
        "receive-po": "ទទួលស្តុក",
        "purchasing": "ការទិញ",
        "po": "បញ្ជាទិញ",
        "setprice": "កំណត់តម្លៃ",
        "pricing": "តារាងតម្លៃ",
        "check-price": "ពិនិត្យតម្លៃ",
        "marketing": "ការផ្សព្វផ្សាយ",
        "program": "កម្មវិធី",
        "loyalty": "ភាពស្មោះត្រង់",
        "e-wallet": "កាបូបលុយអេឡិចត្រូនិច",
        "people": "មនុស្ស",
        "user": "អ្នកប្រើប្រាស់",
        "vendor": "អ្នកផ្គត់ផ្គង់",
        "customer": "អតិថិជន",
        "group": "ក្រុម",
        "report": "របាយការណ៍",
        "sale": "ការលក់",
        "expense": "ចំណាយ",
        "setting": "ការកំណត់",
        "general": "ទូទៅ",
        "role": "តួនាទី",
        "counter": "កន្លែងគិតលុយ",
        "currency": "រូបិយប័ណ្ណ",
        "profile": "ប្រវត្តិរូប",
        "support": "គាំទ្រ",

        // Base Index Layout
        "search": "ស្វែងរក",
        "tool": "ឧបករណ៍",
        "total": "សរុប",
        "no-data": "គ្មានទិន្នន័យ",
        "no": "ល.រ",
        "action": "សកម្មភាព",
        "due-balance": "លុយជំពាក់នៅសល់",
        "settled-by": "ប្រតិបត្តិការដោយ",

        // Product
        "all": "ទាំងអស់",
        "code": "កូដ",
        "sku": "SKU",
        "product-name": "ឈ្មោះផលិតផល",

        // User
        "create-user": "បង្កើតអ្នកប្រើប្រាស់",
        "edit-user": "កែសម្រួលអ្នកប្រើប្រាស់",
        "username": "ឈ្មោះអ្នកប្រើប្រាស់",
        "email": "អ៊ីមែល",
        "phone-number": "លេខទូរស័ព្ទ",
        "designation": "តួនាទី",
        "image": "រូបភាព",
        "first-name": "នាមត្រកូល",
        "last-name": "នាមខ្លួន",
        "address": "អាស័យដ្ឋាន",
        "city": "ទីក្រុង",
        "zipcode": "លេខកូដតំបន់",
        "country": "ប្រទេស",
        "map-url": "តំណរផែនទី",
        "role-active": "តួនាទីសកម្ម",
        "security": "សន្តិសុខ",
        "password": "ពាក្យសម្ងាត់",
        "confirm-pwd": "បញ្ជាក់ពាក្យសម្ងាត់",
        "shift-time": "វ៉េនធ្វើការ",
        "name": "ឈ្មោះ",
        "time": "ម៉ោង",
        "total-sold": "បានលក់សរុប",
        "grand-total-dollar": "សរុបរួម ដុល្លារ",
        "total-receive-dollar": "បានទទួលប្រាក់ដុល្លារសរុប",
        "total-receive-riel": "បានទទួលប្រាក់រៀលសរុប",

        // Entity Base Layout
        "save": "រក្សាទុក",
        "discard": "បោះបង់",
        "update": "ធ្វើបច្ចុប្បន្នភាព",
        "print": "បោះពុម្ព",
        "upload-pic": "ដាក់បញ្ចូលរូបភាព",
        "description": "ការពិពណ៌នា",
        "company": "ក្រុមហ៊ុន",
        "full-name": "ឈ្មោះពេញ",
        "create-group": "បង្កើតក្រុម",
        "edit-group": "កែសម្រួលក្រុម",
        "view-group": "មើលក្រុម",
        "create-vendor": "បន្ថែមអ្នកផ្គត់ផ្គង",
        "edit-vendor": "កែសម្រួលអ្នកផ្គត់ផ្គង",
        "view-vendor": "បង្ហាញអ្នកផ្គត់ផ្គង",
        "create-product": "បន្ថែមផលិតផល",
        "edit-product": "កែសម្រួលផលិតផល",
        "show-product": "បង្ហាញផលិតផល",
        "create-brand": "បន្ថែមម៉ាក",
        "edit-brand": "កែសម្រួលម៉ាក",
        "show-brand": "បង្ហាញម៉ាក",
        "create-cate": "បន្ថែម​ប្រភេទ",
        "edit-cate": "កែសម្រួល​ប្រភេទ",
        "show-cate": "បង្ហាញប្រភេទ",
        "create-sub-cate": "បន្ថែម​ប្រភេទរង",
        "edit-sub-cate": "កែសម្រួល​ប្រភេទ​រង",
        "show-sub-cate": "បង្ហាញប្រភេទរង",
        "edit-type": "កែសម្រួលប្រភេទ",
        "show-type": "បង្ហាញប្រភេទ",
        "pwd-active": "តើចង់ប្តូរពាក្យសម្ងាត់ថ្មីទេ?",
        "brand-active": "ធ្វើឲ្យម៉ាកសកម្ម",
        "tags": "Tags",
        "status": "ស្ថានភាព",
        "available": "សកម្ម",
        "unavailable": "អសកម្ម",
        "sub-cate": "ក្រុមរង",
        "prefix": "អក្សរកាត់ តំណាង",
        "pn-sku": "PN/SKU",
        "model": "ម៉ូដែល",
        "native-name": "ឈ្មោះជាភាសាដើម",
        "variant": "លក្ខណៈ",
        "value": "តម្លៃ",
        "specification": "ការបញ្ជាក់លក្ខណៈ",
        "variant-active": "តើផលិតផលនេះមាន លក្ខណៈផ្សេងៗដែរឬទេ?",
        "no-variant": "មិនមាន លក្ខណៈផ្សេងៗទេ",
        "unit": "ឯកតា",
        "priority": "អាទិភាព",
        "inhouse-product": "ផលិតខ្លួនឯង",
        "product-image": "រូបភាពផលិតផល",
        "import": "នាំចូល",
        "export": "នាំចេញ",
        "template": "ពុម្ពគំរូ",
        "contact-info": "ព័ត៌មានទំនាក់ទំនង",
        "place": "ទីកន្លែង",
        "resturant-ktv": "ភោជនីយដ្ឋាន និង KTV",
        "area": "តំបន់",
        "table": "តុ",
        "room": "បន្ទប់",
        "full-name": "ឈ្មោះពេញ",
        "fax": "ទូរសារ",
        "tin": "អាករលើតម្លៃបន្ថែម",
        "tin-full": "លេខអត្តសញ្ញាណពន្ធ", 
        "registered-at": "ចុះឈ្មោះនៅ",
        "main-business": "អាជីវកម្មគោល",
        "under": "ខាងក្រោម",
        "form-business": "ទម្រង់អាជីវកម្ម",
        "nationality": "សញ្ជាតិ",
        "facebook": "ហ្វេសប៊ុក",
        "website": "គេហទំព័រ",
        "gender": "ភេទ",
        "group-active": "ចង់បន្ថែមក្រុមថ្មីទេ?",
        "create-customer": "បន្ថែមអតិថិជន",
        "edit-customer": "កែសម្រួលអតិថិជន",
        "view-customer": "មើលអតិថិជន",
        "male": "ប្រុស",
        "female": "ស្រី",
        "permission": "សិទ្ធិ",
        "create-role": "បង្កើតតួនាទី",
        "edit-role": "កែសម្រួលតួនាទី",
        "change-pwd": "តើចង់ប្តូរពាក្យសម្ងាត់ថ្មីទេ?",
        "wifi-name": "ឈ្មោះ WiFi",
        "wifi-pwd": "ពាក្យសម្ងាត់ WiFi",
        "thank-you-text": "ឃ្លាសូមអរគុណ",
        "position-info": "ព័ត៌មានទីតាំងទំនិញ",
        "position": "តាំងទំនិញ",
        "create-warehouse": "បន្ថែមឃ្លាំង",
        "edit-warehouse": "កែសម្រួលឃ្លាំង",
        "view-warehouse": "បង្កាញឃ្លាំង",
        "product-name": "ឈ្មោះផលិតផល",
        "brand-name": "ម៉ាកយីហោ",
        "create-set-price": "កំណត់តម្លៃ",
        "edit-set-price": "កែសម្រួលតម្លៃ",
        "product-info": "ព័ត៌មានផលិតផល",
        "price-to-lvl": "កម្រិតតម្លៃ",
        "start-price": "តម្លៃចាប់ផ្តើម",
        "end-price": "តម្លៃចុងក្រោយ",
        "attach-file": "ឯកសារភ្ជាប់",
        "image-file": "រូបភាព និងឯកសារ",
        "qty": "ចំនួន",
        "back": "ថយក្រោយ",
        "bundle": "ផ្គួបបញ្ចូលគ្នា",
        "files": "ឯកសារ",
        "store-location": "ទីតាំងហាង",
        "warehouse-location": "ទីតាំងឃ្លាំង",
        "staff": "បុគ្គលិក",
        "create-staff": "បន្ថែមបុគ្គលិក",
        "edit-staff": "កែសម្រួលបុគ្គលិក",
        "department": "នាយកដ្ឋាន",
        "create-department": "បន្ថែមនាយកដ្ឋាន",
        "edit-department": "កែសម្រួលនាយកដ្ឋាន",
        "po-num": "លេខបញ្ជាទិញ",
        "payment": "ការទូទាត់",
        "create-by": "សកម្មភាពដោយ",
        "receive-by": "បានទទួលដោយ",
        "create-at": "ធ្វើកម្មភាព",
        "update-at": "ធ្វើបច្ចុប្បន្នភាព",
        "cost": "ថ្លៃដើម",
        "total-cost": "ថ្លៃដើមសរុប",
        "made-date": "កាលបរិច្ឆេទផលិត",
        "exp-date": "កាលបរិច្ឆេទផុតកំណត់",
        "remark": "កំណត់សំគាល់",
        "cost-active": "តើការបញ្ជាទិញនេះមានការចំណាយផ្សេងទៀតទេ?",
        "deposit": "បង់ប្រាក់",
        "tax": "ពន្ធអាករ",
        "estimate-arrival": "ប៉ាន់ស្មានការមកដល់",
        "note": "ចំណាំ",
        "color": "ពណ៌",
        "clear-by": "ជម្រះដោយ",
        "create-po": "ចាប់ផ្តើមបញ្ជាទិញ",
        "edit-po": "កែសម្រួលការបញ្ជាទិញ",
        "receive-po": "ទទួលស្តក់",
        "edit-receive-po": "កែសម្រួលការទទួលបញ្ជាទិញ",
        "receive": "ទទួល",
        "return": "បញ្ជូនត្រលប់ទៅវិញ",
        "amount": "ចំនួនទឹកប្រាក់",
        "ordering": "កាម្មង់",
        "receiving": "ការទទួល",
        "issued-considering": "សម្រេចចិត្តលើបញ្ហា",
        "returning-all": "បញ្ជូនត្រលប់ទៅវិញទាំងអស់",
        "returning-some-items": "បញ្ជូនត្រលប់ទៅវិញខ្លះ",
        "completed-received-all": "បានទទួលបានទាំងអស់",
        "completed-by-over": "បញ្ចប់ ដោយលើចំនួន",
        "completed-by-missing": "បញ្ចប់ដោយ ខ្វះចំនួន",
        "completed-by-broken": "បញ្ចប់ដោយ ខូចខាត មិនបញ្ជូនត្រលប់",
        "completed-by-return-some": "បញ្ចប់ដោយបញ្ជូនទំនិញខ្លះទៅវិញ",
        "serial-number": "លេខ​ Serial",
        "warranty": "ធានា",
        "receive-to": "ទទួលពី",
        "clear-po": "ជម្រះ ការបញ្ជាទិញ",
        "deposit-remark": "កំណត់សំគាល់ការបង់ប្រាក់",
        "total-po": "សរុបថ្លៃបញ្ជាទិញ",
        "vendor-address": "អាស័យដ្ឋានអ្នកផ្គត់ផ្គង",
        "total-ordering": "កាម្មង់ទិញសរុប",
        "total-receiving": "បានទទួលសរុប",
        "total-succeed": "ចំនួនជោគជ័យសរុប",
        "total-issued": "បញ្ជាទិញសរុប",
        "total-cancelled": "បោះបង់សរុប",
        "total-draft": "ពង្រាងសរុប",
        "total-delete": "លុបសរុប",
        "total-amount": "ចំនួនទឹកប្រាក់សរុប",
        "total-paid": "ចំណាយសរុប",
        "total-credit": "ជំពាក់សរុប",
        "draft": "ពពង្រាង",
        "cancelled": "បានបោះបង់",
        "date": "កាលបរិច្ឆេទ",
        "product-code": "លេខកូដផលិតផល",
        "adjustment": "ការកែតម្រូ",
        "booking": "ធ្វើការកក់",
        "reserve": "បង្កក់បម្រុងទុក",
        "request": "ស្នើរសុំ",
        "transfer": "ផ្ទេរ",
        "warehouse-request": "សំណើពីឃ្លាំង",
        "warehouse-transfer": "ការផ្ទេរពីឃ្លាំង",
        "store-request": "សំណើពីហាង",
        "store-transfer": "ការផ្ទេរពីហាង",
        "ref-quot-no": "លេខយោងសម្រង់តម្លៃ",
        "refer-down-pay-no": "លេខយោងបង់ប្រាក់",
        "project-name": "ឈ្មោះគម្រោង",
        "client": "អតិថិជន",
        "sale-represent": "តំណាងអ្នកលក់",
        "num-of-day": "ចំនួននៃថ្ងៃ",
        "reason": "ហេតុផល",
        "create-booking": "ចាប់ផ្តើមកក់",
        "edit-booking": "កែសម្រួលការកក់",
        "check-booking": "ពិនិត្យការកក់",
        "view-booking": "មើលការកក់",
        "ok": "យល់ព្រម",
        "cancel": "បោះបង់",
        "instock": "ក្នុង​ស្តុក",
        "information": "ព័ត៌មាន",
        "ref-pro-inv-no": "លេខយោងវិក្កយបត្រប៉ាន់ស្មាន",
        "next-pay-est-date": "កាលបរិច្ឆេទទូទាត់បន្ទាប់",
        "request-from": "ស្នើសុំពី",
        "request-to": "ស្នើសុំទៅកាន់",
        "core-feature": "មុខងារស្នូល",
        "technical-sale": "បច្ចេកទេសសម្រាប់អ្នកលក់",
        "executive-summary": "ប្រតិបត្តិការសង្ខេប",
        "full-description": "ការពិពណ៌នាពេញលេញ",
        "attached-file": "ឯកសារភ្ជាប់",
        "this-week": "សប្តាហ៍នេះ",
        "this-month": "ខែនេះ",
        "this-year": "ឆ្នាំនេះ",
        "nearly-out-of-stock": "ជិតអស់ស្តុក",
        "nearly-expire-product": "ជិតផុតកំណត់",
        "cut-stock": "កាត់ស្តុក",
        "add-stock": "បញ្ចូលស្តុក",
        "current-stock": "ស្តុកបច្ចុប្បន្ន",
        "broken": "ខូច",
        "lost": "បាត់បង់",
        "other": "ផ្សេងទៀត",
        "additional-stock": "ស្តុកបន្ថែម",
        "vendor-promotion": "អ្នកផ្គត់ផ្គង់បន្ថែមជូន",
        "staff": "បុគ្គលិក",
        "animal": "ខូចខាត",
        "life-value": "ផុតសពលភាព",
        "penalty": "ពិន័យ",
        "extra-cost": "តម្លៃបន្ថែម",
        "by": "ដោយ",
        "open-reviewing": "បើកចំហ-កំពុងពិនិត្យ",
        "open-rejected": "បើកចំហ-បដិសេធ",
        "closed-approved": "បិទ-អនុម័ត",
        "closed-rejected": "បិទ-បដិសេធ",
        "own-request": "សំណើរផ្ទាល់ខ្លួន",
        "own-transfer": "ផ្ទេរផ្ទាល់ខ្លួន",
        "other-request": "សំណើផ្សេងៗ",
        "other-transfer": "ផ្ទេរផ្សេងៗ",
        "reviewing": "កំពុងពិនិត្យ",
        "revision-item": "កំណត់ទំនិញឡើងវិញ",
        "reject": "បដិសេធ",
        "accepted": "បានឯកភាព",
        "preparing": "កំពុងរៀបចំ",
        "delivering": "កំពុងចែកចាយ",
        "receiving": "កំពុងទទួល",
        "completed": "បានបញ្ចប់",
        "execution": "ប្រតិបត្តិ",
        "issue-date": "កាលបរិច្ឆេទ​ចេញ",
        "lastest-update": "ធ្វើបច្ចុប្បន្នភាពចុងក្រោយ",
        "record-number": "លេខកំណត់ត្រា",
        "transfer-to": "ផ្ទេរទៅ",
        "request-from": "ស្នើសុំពី",
        "transfer-from": "ផ្ទេរពី",
        "acknowledge-by": "ទទួលស្គាល់ដោយ",
        "point": "ពិន្ទុ",
        "set-point": "កំណត់ពិន្ទុ",
        "start-point": "ពិន្ទុចាប់ផ្តើម",
        "end-point": "ពិន្ទុ​បញ្ចប់",
        "loyalty-active": "ធ្វើឱ្យភាពស្មោះត្រង់សកម្ម",
        "create-loyalty": "បន្ថែមភាពស្មោះត្រង់",
        "edit-loyalty": "កែសម្រួលភាពស្មោះត្រង់",
        "balance": "សមតុល្យ",
        "serial": "Serial",
        "create-counter": "បន្ថែមកន្លែងគិលលុយ",
        "edit-counter": "កែសម្រួលកន្លែងគិលលុយ",
        "create-e-wallet": "បន្ថែមកាបូបលុយអេឡិចត្រូនិច",
        "top-up-e-wallet": "បញ្ចូលលុយអេឡិចត្រូនិច",
        "dollar": "ដុល្លារ",
        "riel": "រៀល",
        "stand-alone-active": "តើនេះជាប្រូក្រាមដំណើរការដាច់តែឯងមែនទេ?",
        "create-program": "បង្កើតកម្មវិធី",
        "edit-program": "កែសម្រួលកម្មវិធី",
        "check-program": "ពិនិត្យកម្មវិធី",
        "view-program": "មើលកម្មវិធី",
        "program-name": "ឈ្មោះកម្មវិធី",
        "program-id": "លេខសម្គាល់កម្មវិធី",
        "message": "សារ",
        "price-limit": "តម្លៃកំណត់",
        "cash-type": "ប្រភេទសាច់ប្រាក់",
        "schedule": "កាលវិភាគ",
        "item": "ទំនិញ",
        "is-zero": "រួមទាំងគ្មានស្តុក",
        "create-request": "ចាប់ផ្តើមស្នើ",
        "edit-request": "កែសម្រួលសំណើ",
        "check-request": "ពិនិត្យសំណើ",
        "view-request": "មើលសំណើ",
        "create-transfer": "ចាប់ផ្តើមការផ្ទេរ",
        "edit-transfer": "កែសម្រួលការផ្ទេរ",
        "check-transfer": "ពិនិត្យការផ្ទេរ",
        "view-transfer": "មើលការផ្ទេរ",
        // "edit-counter": "Edit Counter",
        "invoice": "វិក្កយបត្រ",
        "today-invoice": "វិក្កយបត្រថ្ងៃនេះ",
        "sale-revenue": "ចំណូលពីការលក់",
        "today-sale-revenue": "ចំណូលពីការលក់ថ្ងៃនេះ",
        "today": "ថ្ងៃនេះ",
        "week": "សប្តាហ៍",
        "month": "ខែ",
        "year": "ឆ្នាំ",
        "yesterday": "ម្សិលមិញ",
        "last-week": "សប្តាហ៍មុន",
        "last-month": "ខែមុន",
        "last-year": "ឆ្នាំមុន",
        "credit": "ជំពាក់",
        "sale-report": "របាយការណ៍លក់",
        "po-report": "របាយការណ៍ PO",
        "today-credit": "ជំពាក់ ថ្ងៃនេះ",
        "new-arrival": "ទើបដល់ថ្មី",
        "nearly-exp": "ជិតផុតកាលបរិច្ឆេទ",
        "nearly-out": "ជិតអស់ស្តុក",
        "financial": "ហិរញ្ញវត្ថុ",
        "invoice-num": "លេខ​វិក្កយបត្រ",
        "receive-dollar": "ទទួល ($)",
        "receive-riel": "ទទួល (រៀល)",
        "credit-dollar": "ជំពាក់($)",
        "credit-riel": "ជំពាក់ (រៀល)",
        "total-dollar": "សរុប ($)",
        "total-riel": "សរុប (រៀល)",
        "method": "វិធីសាស្រ្ត",
        "withdraw": "ដកប្រាក់",
        "withdraw-print": "ដកប្រាក់ហើយបោះពុម្ព",
        "product-report": "របាយការណ៍ផលិតផល",
        "inventory-report": "របាយការណ៍សារពើភ័ណ្ឌ",
        "main-balance": "សមតុល្យគោល",
        "num-of-exp": "ចំនួនផុតកំណត់",
        "num-of-out-stock": "ចំនួនអស់ពីស្តុក",
        "money-in": "លុយចូល",
        "money-out": "លុយចេញ",
        "is-serial": "តើមានលេខ Serialទេ?",
        "accounting": "គណនេយ្យ",
        "credit-management": "គ្រប់គ្រងការជំពាក់",
        "credit-collection": "ប្រមូលការជំពាក់",
        "credit-settlement": "ទូទាត់ការជំពាក់",
        "total-clients": "អតិថិជនសរុប",
        "total-transactions": "ប្រតិបត្តិការសរុប",
        "total-credit-settled": "លុយជំពាក់បានទូទាត់សរុប",
        "due-credit-balance": "លុយនៅជំពាក់",
        "client-id": "លេខសម្គាល់អតិថិជន",
        "client-name": "ឈ្មោះអតិថិជន",
        "amount-should-be-settled": "ចំនួនទឹកប្រាក់គួរទូទាត់",
        "settle": "ទូទាត់ការជំពាក់",
        "clear-credit": "ជម្រះលុយជំពាក់",
        "invoice-number": "លេខ​វិក្កយបត្រ",
        "sale-representative": "តំណាងអ្នកលក់",
        "down-payment": "លុយកក់",
        "purchased-date": "ថ្ងៃបាន​ទិញ",
        "latest-updated-date": "បច្ចុប្បន្នភាពចុងក្រោយ",
        "estimate-return-date": "រំពឹងសងត្រឡប់មកវិញ",
        "sub-total": "សរុបរង",
        "discount": "បញ្ចុះតម្លៃ",
        "vat": "អាករ",
        "grand-total": "បូកសរុប",
        "account": "គណនី",
        "loging": "ចាប់ផ្តើម...",
        "product-management": "គ្រប់គ្រងផលិតផល",
        "auto-settle": "ទូទាត់ស្វ័យប្រវត្តិ",
        "view-credit": "មើលការជំពាក់",
        "credit-balance": "សមតុល្យការជំពាក់",
        "english": "ភាសាអង់គ្លេស",
        "khmer": "ភាសាខ្មែរ",
        "create-type": "បង្កើតប្រភេទ",
        "create-adjustment": "ចាប់ផ្តើមកែតម្រូវ",
        "inventory-management": "គ្រប់គ្រងសារពើភ័ណ្ឌ",
        "view-receive-po": "មើលការទទួលស្តក់",
        "hr": "ធនធានមនុស្ស",
        "create-client": "បន្ថែមអតិថិជន",
        "edit-client": "កែសម្រួលអតិថិជន",
        "view-client": "មើលអតិថិជន",
        "sell": "លក់",
        "sell-history": "ប្រវត្តិការលក់",
        "cash-management": "គ្រប់គ្រងសាច់ប្រាក់",
        "sell-report": "របាយការណ៍លក់",
        "item-master": "បញ្ជីទំនិញ",
        "client-management": "គ្រប់គ្រងអតិថិជន",
        "staff-management": "គ្រប់គ្រងបុគ្គលិក",
        "configuration": "ការកំណត់​រចនាសម្ព័ន្ធ",
        "dine-in": "ញុាំនៅនឹងកន្លែង",
        "take-away": "វេចខ្ចប់",
        "view-order": "មើលការបញ្ជាទិញ",
        "kitchen": "ផ្ទះបាយ",
        "item-collection": "បណ្តុំទំនិញ",
        "restaurant-ktv": "ភោជនីយដ្ឋាន-KTV",
        "create-room": "បន្ថែមបន្ទប់",
        "edit-room": "កែសម្រួលបន្ទប់",
        "create-table": "បន្ថែមតុ",
        "edit-table": "កែសម្រួលតុ",
        "logo": "និមិត្តសញ្ញា",
        "full-logo": "និមិត្តសញ្ញាពេញ",
        "retrieve": "ទៅយក",
        "updating": "កំពុងធ្វើបច្ចុប្បន្នភាព",
        "sub-cate-active": "ធ្វើឱ្យប្រភេទរងសកម្ម",
        "cate-active": "ធ្វើឱ្យប្រភេទសកម្ម",
        "username-password-incorrect": "ឈ្មោះអ្នកប្រើប្រាស់ ឬពាក្យសម្ងាត់មិនត្រឹមត្រូវ",
        "restaurant-table": "តុ",
        "ktv-room": "បន្ទប់",
        "new-order": "ការបញ្ជាទិញថ្មី",
        "table-room": "បន្ទប់ តុ",
        "total-discount": "បញ្ចុះតម្លៃសរុប",
        "grand-total-riel": "ប្រាក់រៀលសរុប",
        "tax-vat": "ពន្ធអាករ",
        "send-to-kitchen": "បញ្ចូនទៅផ្ទះបាយ",
        "counter-info": "ព័ត៌មានកន្លែងគិតលុយ",
        "copy": "ចម្លង",
        "copied": "បានចម្លង !",
        "price": "តម្លៃ",
        "dis": "ប.ត",
        "change-table": "ផ្លាស់ប្តូរ តុ",
        "change-client": "ផ្លាស់ប្តូរអតិថិជន",
        "merge-bill": "ផ្គួបវិក្កយបត្រ",
        "kot-print": "បោះពុម្ពទៅផ្ទះបាយ",
        "cancel-all": "បោះបង់ទាំងអស់",
        "check-bill": "គិតលុយ",
        "cash-in": "ដាក់ប្រាក់ចូល",
        "cash-out": "ដកប្រាក់ចេញ",
        "transaction": "ប្រតិបត្តិការ",
        "tools": "ឧបករណ៍",
        "cash-in-out": "ប្រាក់ចូល-ចេញ",
        "total-cash-in": "ប្រាក់ចូលសរុប",
        "total-cash-out": "ប្រាក់ចេញសរុប",
        "total-transaction": "ប្រតិបត្តិការសរុប",
        "cash-in-dollar": "ប្រាក់ចូល(ដុល្លារ)",
        "cash-in-riel": "ប្រាក់ចូល(រៀល)",
        "cash-out-dollar": "ប្រាក់ចេញ(ដុល្លារ)",
        "cash-out-riel": "ប្រាក់ចេញ(រៀល)",
        "balance-dollar": "សមតុល្យប្រាក់(ដុល្លារ)",
        "balance-riel": "សមតុល្យប្រាក់(រៀល)",
        "sold-dollar": "លក់បាន(ដុល្លារ)",
        "sold-riel": "លក់​បាន(រៀល)",
        "cancel-order": "បោះបង់ការបញ្ជាទិញ",
        "cash": "សាច់ប្រាក់",
        "payway": "ភ្នាក់ងារទូទាត់",
        "debit": "Debit/Credit",
        "total-payment": "ទូទាត់សរុប",
        "transaction-no": "លេខរៀងប្រតិបត្តិការ",
        "last-four-digits": "លេខបួនខ្ទង់ចុងក្រោយ",
        "expected-returned": "រំពឹងសង់មកវិញ",
        "search-client": "ស្វែងរកអតិថិជន",
        "cancel-remark": "កត់សម្គាល់ការបោះបង់",
        "already-selected": "បានជ្រើសរើសរួចហើយ",
        "change-table-room": "ផ្លាស់ប្តូរ បន្ទប់ តុ",
        "merge-from": "បញ្ចូលពី",
        "merge-with": "បញ្ចូលជាមួយ",
        "merge-to": "បញ្ចូលទៅកាន់",
        "service": "សេវាកម្ម",
        "return": "ប្រាក់អាប់",
        "paid-by": "បង់តាមរយៈ",
        "exchange-rate": "អត្រាប្តូរប្រាក់",
        "order-person": "អ្នក​បញ្ជា​ទិញ",
        "cashier": "អ្នកគិតលុយ",
        "sales-associate": "សហការីផ្នែកលក់",
        "bill-no": "លេខវិក័យប័ត្រ",
        "receipt": "វិក័យប័ត្រ",
        "served": "បានលើជូនរួចរាល់",
        "clear": "ជម្រះ",
        "tel": "ទូរស័ព្ទ",
        "membership": "សមាជិកភាព",
        "id": "លេខសម្គាល់",
        "close-statement": "បិទ Statement",
        "balance-usd": "សមតុល្យ (ដុល្លារ)",
        "balance-khr": "សមតុល្យ (រៀល)",
        "close": "បិទ",
        "currency-exchange": "អត្រាប្តូរប្រាក់",
        "sale-ref": "លេខយោងលក់",
        "sale-associate": "សហការីផ្នែកលក់",
        "total-change-dollar": "អាប់(ដុល្លារ)",
        "total-change-riel": "អាប់​(រៀល)",
        "total-bills": "វិក័យប័ត្រសរុប",
        "gross-sale": "លក់សរុប",
        "net-sale": "លក់បានសុទ្ធ",
        "service-charge": "ថ្លៃសេវាកម្ម",
        "item-wise": "អំពីទំនិញ",
        "day-summary": "សង្ខេបប្រចាំថ្ងៃ",
        "cashier-wise": "អំពីអ្នកគិតលុយ",
        "settlement": "ការទូទាត់",
        "sales-reports": "របាយការណ៍លក់",
        "sales-history": "ប្រវត្តិនៃការលក់",
        "taxation": "ពន្ធ",
        "expanse": "ការចំណាយ",
        "coupon": "គូប៉ុង",
        "gift-card": "កាតអំណោយ",
        "check-in": "ពិនិត្យចូល ",
        "check-out": "ពិនិត្យចេញ",
        "loyalty-e-wallet": "Loyalty & កាបូបអេឡិចត្រូនិច",
        "grand-total-point": "សរុបពិន្ទុទាំងអស់",
        "grand-total-usd": "សរុបប្រាក់(ដុល្លារ)",
        "grand-total-khr": "សរុបប្រាក់(រៀល)",
        "basic-information": "ព័ត៌មានមូលដ្ឋាន",
        "address-information": "ព័ត៌មានអាស័យដ្ឋាន",
        "change-dollar": "ប្តូរប្រាក់ (ដុល្លារ)",
        "change-riel": "ប្តូរប្រាក់ (រៀល)",
        "sale-detail": "ព័ត៌មានលម្អិតអំពីការលក់",
        "cash-control": "ចរន្តសាច់ប្រាក់",
        "payment-received": "បានទទួលការទូទាត់",
        "sale-summary": "របាយការណ៍លក់សង្ខេប",
        "expensed": "បានចំណាយ",
        "sold": "បានលក់",
        "changed": "អាប់",
        "start-at": "ចាប់ផ្តើមពី",
        "end-at": "បញ្ចប់ពី",
        "from": "ពី",
        "unit-price": "តម្លៃឯកតា",
        "logout": "ចាកចេញ",
        // "physical": "ផលិតផលស្តក់",
        // "service": "សេវាកម្ម",
        // "digital": "ឌីជីថល",
        // "bundle": "ផលិតផលផ្គួប",
        // "package": "កញ្ចប់/កេះ",
        // "default": "លំនាំដើម",
        "reset": "កំណត់ឡើងវិញ",
        "total-client": "ចំនួនអតិថិជនសរុប",
        "total-group": "ចំនួនក្រុមសរុប",
        "full-screen": "អេក្រង់ពេញ",
        "exit-full-screen": "ចាកចេញពិអេក្រង់ពេញ",
        "num-client-walkin": "ចំនួនភ្ញៀវដែលចូលហាង",
        "search-product": "ស្វែងរកទំនិញ",
        "edit-profile": "កំណែប្រវត្តិរូប",
        "edit": "កំណែ",
        "cs-id": "លេខសម្គាល់សហការីផ្នែកលក់",
        "cs-name": "ឈ្មោះសហការីផ្នែកលក់",
        "customer-service-id": "លេខសម្គាល់សហការីផ្នែកលក់",
        "delete": "លុប",
        "coupon-gift-card": "គូប៉ុង/កាតអំណោយ",
        "changed": "លុយអាប់",
        "received": "បានទទួល",
        "your-order": "ការកុម៉្មងរបស់អ្នក",
        "khqr": "KHQR",
        "membercard": "កាតសមាជិក",
        "pre-sale": "សហការីផ្នែកលក់",
        "receipt-no": "លេខវិក័យប័ត្រ",
        "good-service-title": "ឈ្មោះផលិតផល",
        "good-service-description": "ការពិពណ៌នាអំពីផលិតផល",
        "good-service-variant": "លក្ខណៈផលិតផល",
        "stock": "ស្តុក",
        "no-description": "គ្មានការពិពណ៌នា",
        "no-variant": "គ្មានលក្ខណៈ",
        "walk-in": "ចំនួនអតិថិជន",
        "change": "លុយអាប់",
        "total-amount-usd": "ចំនួនលុយសរុប USD",
        "total-amount-khr": "ចំនួនលុយសរុប KHR",
        "sales-credits": "លក់ជំពាក់",
        "expected-return": "ថ្ងៃដែលត្រូវសង",
        "updated": "ដែលបានកំណែរួចរាល់",
        "seller": "អ្នកលក់",
        "received-payment-remark": "កំណត់សម្គាល់ការទូទាត់ដែលបានទទួល",
        "start": "កាលបរិច្ឆេទ",
        "paid-invoice": "វិក័យបត្របង់ហើយ",
        "void-invoice": "វិក័យបត្រមោឃៈ",
        "total-invoice": "វិក័យបត្រសរុប",
        "cash-in-amount": "ចំនួនលុយដាក់ចូល",
        "receive-amount": "ចំនួនលុយទទួលបាន",
        "total-change": "លុយអាប់សរុប",
        "total-expensed": "ចំណាយសរុប",
        "total-balance": "សមតុល្យសរុប",
        "received-via-payway": "បានទទួលតាមរយៈ Payway",
        "payway-dollar": "Payway (ដុល្លារ)",
    } 
}
import baseAPI from './baseAPI'
import authAPI from '../api/authAPI'

const login = async (username, password, storelocation, counter) => {
    return await baseAPI.post('retaillogin', { username, password, storelocation, counter })
}

const logout = async () => {
    return await baseAPI.post("logout")
}

const verifyToken = async (counterId) => {
    return baseAPI.get("verifyToken", {params: {counter: counterId}})
}

export default { login, logout, verifyToken }
import baseAPI from './baseAPI'

const getTotalDineIn = async (storeLocation) => {
    return await baseAPI.get('order', { params: {
        q: '',
        limit: 4,
        page: 1,
        storeLocation: storeLocation,
        orderType: 'dine-in',
        type: 'view-order'
    }})
}

const getTotalTakeaway = async (storeLocation) => {
    return await baseAPI.get('order', { params: {
        q: '',
        limit: 4,
        page: 1,
        storeLocation: storeLocation,
        orderType: 'takeaway',
        type: 'view-order'
    }})
}

const getTotalKitchen = async (storeLocation) => {
    return await baseAPI.get('order', { params: {
        q: '',
        limit: 4,
        page: 1,
        storeLocation: storeLocation,
        orderType: 'all',
        type: 'kitchen'
    }})
}

export default { getTotalDineIn, getTotalKitchen, getTotalTakeaway}
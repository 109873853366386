// API
export const apiUrl = () => {
    if (process.env.REACT_APP_MODE === "development") {
        return `${process.env.REACT_APP_DEVELOPMENT_URL}`
    } else if (process.env.REACT_APP_MODE === "staging") {
        return `${process.env.REACT_APP_STAGING_URL}/api/`
    } else if (process.env.REACT_APP_MODE === "production") {
        return `${process.env.REACT_APP_PRODUCTION_URL}/api/`
    }
}

// Color
export const red = "#FF0000"
export const green = "#01C400"
export const blue = "#001DC4"
export const yellow = "#FFFF00"
export const orange = "#FFA500"
export const modernizeti = "#25B35F"

import React, { lazy, Suspense, useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { I18nProvider } from './lang'
import ProtectedRoute from './routes/ProtectedRoute'
// import { getTotalDineIn, getTotalKitchen, getTotalTakeaway } from './redux/features/orderNumSlice'
// import ProtectedRoute from './routes/ProtectedRoute'

const Login = lazy(() => import('./pages/auth/Login'))
const PageNotFound = lazy(() => import('./pages/error/Error'))
const AppLayout = lazy(() => import('./pages/app'))

function App() {

    // const dispatch = useDispatch()
    const locale = useSelector(state => state.locale.locale)
    // const user = useSelector(state => state.user.user)
    const loadingState = useSelector(state => state.user.status)

    // useEffect(() => {
    //     dispatch(getTotalDineIn(user?.store?._id))
    //     dispatch(getTotalTakeaway(user?.store?._id))
    //     dispatch(getTotalKitchen(user?.store?._id))
    // }, [user])

    if (loadingState === 'loading') {
        return <span>Loading...</span>
    }

    return (
        <I18nProvider locale={locale}>
            <BrowserRouter>
                <Suspense fallback={<div className='centered loader'> Loading...</div>}>
                    <Routes>
                        <Route 
                            path='login' 
                            element={<Login />} 
                        />
                        <Route 
                            path={`/${process.env.REACT_APP_PRO_PREFIX}/*`} 
                            element={<ProtectedRoute>
                                <AppLayout />
                            </ProtectedRoute>} 
                        />
                        <Route 
                            path='/' 
                            element={<Navigate to={`/${process.env.REACT_APP_PRO_PREFIX}`} replace />} 
                        />
                        <Route 
                            path={`/${process.env.REACT_APP_PRO_PREFIX}`} 
                            element={<Navigate to={`/${process.env.REACT_APP_PRO_PREFIX}/sell`} replace />} 
                        />
                        <Route path='*' element={<PageNotFound />} />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </I18nProvider>
    )
}

export default App

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import currencyAPI from "../../api/currencyAPI"

const initialState = {
    currencies: {},
    general: {},
    counter: {},
    group: {},
    store: {},
    additionalPrice: {},
    point: 0,
    loading: false,
    // isPromotion: true, //Marketing Module applied.
    message: ""
}

export const getConfig = createAsyncThunk(
    "config/getConfig",
    async (_, thunkAPI) => {
        try {
            const response = await currencyAPI.getConfig()
            console.log("get config: ", response.data.data)
            return response.data.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    })

const configSlice = createSlice({
    name: "config",
    initialState,
    reducers: {},
    extraReducers: {
        [getConfig.pending]: state => {
            state.loading = true
        },
        [getConfig.fulfilled]: (state, action) => {
            const { currencies, general, counter, group, store } = action.payload
            state.loading = false
            state.currencies = currencies
            state.general = general
            state.counter = counter
            state.group = group
            // state.point = loyaltypoints.point
            state.store = store
            state.additionalPrice = {...store.additionalPrice}
        },
        [getConfig.rejected]: (state, action) => {
            state.loading = false
            state.message = action.payload
        }
    }
})

export default configSlice.reducer



import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import storeLocationAPI from '../../api/storeLocationAPI'

// const initState = {
//     storeLocationCode: '',
//     status: false,
//     deleteStatus: false,
//     information: {
//         name: "",
//         nativeNamve: "",
//         description: "",
//         email: "",
//         phoneNumber: "",
//         website: "",
//         facebook: "",
//         wifiName: "",
//         pwdWifi: "",
//         thankYouTxt: "",
//     },
//     addressInfo: {
//         address: "",
//         zipCode: "",
//         country: "",
//         city: "",
//         mapUrl: "",
//     },
//     storeLocationImage: "",
//     storeLocationPosition: [],
//     createdBy: ""
// }

export const getStoreLocation = createAsyncThunk('storelocation', async (_, thunkAPI) => {
    try {
        const res = await storeLocationAPI.getStoreLocation('', 5000, 1)
        return res.data.data
    } catch (error) {
        return thunkAPI.rejectWithValue(error)
    }
})


const storeLocationSlice = createSlice({
    name: 'store',
    initialState: {
        store: [],
        status: null,
        message: ''
    },
    reducers: {},
    extraReducers: {
        [getStoreLocation.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getStoreLocation.fulfilled]: (state, { payload }) => {
            state.store = payload
            state.status = 'success'
        },
        [getStoreLocation.rejected]: (state, { payload }) => {
            state.status = 'failed'
            state.message = payload.message
        },
    }
})

// export const { changeLocale } = storeLocationSlice.actions

export default storeLocationSlice.reducer
import baseAPI from './baseAPI'

const getStoreLocation = async (value, limit, page) => {
    return await baseAPI.get(`storelocation`, { params: {
        q: value,
        limit: limit,
        page: page
    }})
}

export default { getStoreLocation }
import React from 'react'
import ReactDOM from 'react-dom/client'
import { store } from './redux/store'
import { Provider } from 'react-redux'

import { getGeneral } from './redux/features/generalSlice'

import "./assets/scss/style.scss"
import 'antd/dist/antd.less'
import 'react-perfect-scrollbar/dist/css/styles.css'

import App from './App'
import { checkAuthSession } from './redux/features/authSlice'
import { getStoreLocation } from './redux/features/storeLocationSlice'

store.dispatch(getGeneral())
store.dispatch(getStoreLocation())
store.dispatch(checkAuthSession())



const root = ReactDOM.createRoot(document.getElementById('root'))


root.render(
    <Provider store={store}>
        <App />
    </Provider>
)
